.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1
}


.menuItemDiv {
  margin-left: 10px;
  float: left;
  line-height: initial;
  margin-top: 0px !important;
}

.menuItemLabel {
  padding: 6px 16px 6px 16px;
  cursor: pointer;
  float: left;
  line-height: initial;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Helvetica Neue BOLD' !important;
  font-weight: 700 !important;
}

.menuItemLabelActive {
  padding: 6px 16px 6px 16px;
  cursor: pointer;
  float: left;
  line-height: initial;
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Helvetica Neue' !important;
  font-weight: 600 !important;
  background: #492728 !important;
  color: #fff !important;
  border-radius: 3px !important;
}