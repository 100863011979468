@font-face {
	font-family: 'Darker Grotesque';
	src: url('../public/DarkerGrotesque-Regular.ttf')
}

@font-face {
	font-family: 'Helvetica Neue';
	src: url('../public/HelveticaNeueLt.ttf')
}

@font-face {
	font-family: 'Helvetica Neue Bold';
	src: url('../public/HelveticaNeueMed.ttf')
}

@font-face {
	font-family: 'Roboto Regular';
	src: url('../public/vni.common.VTIMESN.ttf')
}

@font-face {
	font-family: 'Anton Regular';
	src: url('../public/Anton-Regular.ttf')
}


@font-face {
	font-family: 'Arvo Regular';
	src: url('../public/Arvo-Regular.ttf')
}



.ag-header-container {
  width: 100% !important;
}

.ag-header-row-column {
  background: #222;
  width: 100% !important;
  color: #fff !important;
  font-weight: 600 !important;
}

button {
  cursor: pointer !important;
}

img {
  image-rendering: -moz-crisp-edges !important;
  image-rendering: -o-crisp-edges !important;
  image-rendering: -webkit-optimize-contrast !important;
  -ms-interpolation-mode: nearest-neighbor !important;
}

a {
  color: #1e0040;
}

input {
  background: white !important;
  color: #000 !important;
}

.MuiInputBase-root {
  border-radius: 3px !important;
}

label[data-shrink='false']
  + .MuiInputBase-formControl
  .css-pb771q-MuiInputBase-input-MuiOutlinedInput-input {
  background: white !important;
}

.form-control {
  margin-top: 20px !important;
  width: 47% !important;
  padding-right: 10px !important;
}


.ag-icon-asc {
  color: #fff !important;
}

.ag-icon-desc {
  color: #fff !important;
}

.ag-theme-alpine .ag-icon-filter::before {
  color: #303030 !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  color: orange !important;
}


.ag-theme-alpine .ag-icon-previous::before {
  color: #000 !important;
}

.ag-theme-alpine .ag-icon-next::before {
  color: #000 !important;
}

.ag-theme-alpine .ag-icon-first::before {
  color: #000 !important;
}

.ag-theme-alpine .ag-icon-last::before {
  color: #000 !important;
}

.ag-theme-alpine .ag-icon-small-down::before {
  color: black !important;
}

.bg-green {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #64748b;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: calc(0.25rem - 1px) 0.25rem;
  height: 1.25rem;
  border: 1px solid transparent;
  min-width: 1.25rem;
  font-weight: 600;
  font-size: 0.625rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background: #2fb344 !important;
  border: 1px solid #2fb344;
}

.bg-orange {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  min-width: 0;
  min-height: auto;
  padding: 0;
  border-radius: 100rem;
  background: #f76707 !important;
  border: 1px solid #f76707;
}

.bg-danger {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  min-width: 0;
  min-height: auto;
  padding: 0;
  border-radius: 100rem;
  background: #d63939 !important;
  border: 1px solid #d63939;
}

.search-container {
  width: 100%;
  margin-bottom: 8px;
  margin-top: 0px;
}

.container-margin {
  margin-right: 30px;
}

.fileMargin {
  font-size: 20px;
  margin-top: 0px;
}

.fileImage {
  width: 45px;
  padding-right: 5px;
  float: left;
}

.filepara {
  float: left;
  margin-top: 8px;
  margin-bottom: 0px;
}

.pagesIcon {
  width: 45px;
  padding-right: 5px;
  float: left;
  margin-left: 30px;
}

.pagesPara {
  float: left;
  margin-top: 8px;
  margin-bottom: 0px;
}

.pagesContainer {
  font-size: 20px;
  margin-top: 9px;
}

.MuiDropzoneArea-root {
  padding: 40px 60px;
  background-color: #f8f8f8 !important;
  border: 1px dashed #c1c1c1 !important;
}

.MuiDropzoneArea-icon {
  color: #492728 !important;
}

.MuiDropzoneArea-text {
  color: #000 !important;
  font-size: 20px !important;
}

.MuiCardContent-root > form > div > span {
  display: none;
}

.MuiGrid-spacing-xs-1 {
  margin-top: 10px !important;
}

body {
  background: #f5f5f5 !important;
  color: #000 !important;
}

body, a, b, div, button, select, input, label {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
  /* font-weight: 600 !important; */
}

button, select, input, label, .MuiTypography-body2, b, textarea {
  font-weight: 600 !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Darker Grotesque", Helvetica, Arial, "Lucida Grande", sans-serif !important;
  font-weight: 600 !important;
}

.ag-cell-wrapper {
  font-weight: 600;
}

fieldset {
  border-radius: 0px !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000 !important;
}

.position {
  display: none !important;
}

.acc-heading {
  background: #303030;
  color: #fff;
  padding: 8px 15px;
}


.acc-border {
  border: 1px solid #d9d9d9 !important;
}

.pdfviewer {
  height: 630px !important;
}

.login-links {
  padding: 0px 7px !important;
  color: #000 !important;
  background: transparent !important;
  font-weight: 600 !important;
}

.login-links:hover {
  text-decoration: underline !important;
}

.Login-head {
  margin-bottom: 0px !important;
}

.Login-subhead {
  margin-top: 5px !important;
  font-size: 36px;
  text-align: left;
  margin-bottom: 15px;
  letter-spacing: -0.5px;
}

.social-btn {
  margin: 0 auto !important;
  margin-top: 10px !important;
  width: 100% !important;
  font-size: 15px !important;
  height: 40px !important;
}

.social-btn > div {
  display: inline-flex !important;
}

.social-btn > div > div > svg {
  width: 18px !important;
}

.main-content {
  background: #f7f7f7 !important;
}

.top-menu-item {
  background: transparent !important;
}

.top-menu-item:hover {
  color: #492728 !important;
}

.ag-theme-alpine .ag-ltr input[class^='ag-'][type='text'] {
  border: 1px solid #cbcbcb !important;
}

.Breadcrumb-heading {
  float: left !important;
  width: 100% !important;
  margin-bottom: 0px !important;
}

.Breadcrumb-link {
  float: left !important;
  width: 100% !important;
}

.nav-item {
  color: #000 !important;
}

.nav-item:hover {
  color: #492728 !important;
}

.MuiListItemButton-gutters {
  background-color: transparent !important;
}

label {
  color: #000 !important;
}

.acc-head {
  color: #000 !important;
}

.user-item {
  color: #000 !important;
  border-radius: 6px;
  padding-bottom: 10px !important;
}

.user-item:hover {
  background-color: #F2D9D0  !important;
  color: #492728  !important;
  
}


.meta-btn {
  margin: 0px !important;
  margin-top: 0px !important;
}

.documentc-btn {
  margin: 0 auto !important;
  margin-bottom: 8px !important;
}

.download-btn {
  margin-bottom: 15px !important;
  margin-top: 0px !important;
  float: right;
  font-weight: 600 !important;
}

.raw-data {
  overflow: auto !important;
  width: 100% !important;
  max-width: 100% !important;
  display: block !important;
  /* max-height: 75vh !important; */
  font-size: 14px !important;
  padding-top: 5px !important;
  height: 90vh !important;
}

.raw-datas {
  overflow: auto !important;
  width: 100% !important;
  max-width: 100% !important;
  display: block !important;
  /* max-height: 75vh !important; */
  font-size: 14px !important;
  padding-top: 5px !important;
  height: 100vh;
}

.raw-datas > img {
  width: 500px !important;
}


.raw-pdf {
  overflow: hidden !important;
  width: 100% !important;
  max-width: 100% !important;
  display: block !important;
  /* max-height: 75vh !important; */
  font-size: 14px !important;
  padding-top: 5px !important;
  height: 82vh;
}

.raw-pdf > img {
  width: 500px !important;
}


.table-data {
  overflow: auto !important;
  width: 100% !important;
  display: flex !important;
  height: 450px !important;
  font-size: 12px !important;
  margin-top: 10px !important;
}

.table-responsive {
  overflow-x: auto !important;
  display: block !important;
  width: 100% !important;
}

.card-table {
  text-align: left !important;
  border-spacing: 0px;
  border: 1px solid #d3d3d3;
  width: 500px;
}

.thead-bg {
  background: #492728 !important;
  color: #fff !important;
}

.th-text {
  border: 1px solid #492728 !important;
  font-size: 12px !important;
  padding: 6px 7px !important;
  width: 50% !important;
  font-weight: 600 !important;
}

.MuiTab-root {
  z-index: 2 !important;
}

.MuiSvgIcon-fontSizeMedium {
  color: #303030 !important;
  height: 0.8em !important;
}

.td-text {
  padding: 10px 10px !important;
  color: #000;
  font-size: 13px !important;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.MuiIconButton-root:hover {
  background: transparent !important;
}

.searchpage {
  border: 1px solid #e1e1e1 !important;
}

.wrap-meta {
  padding: 5px 35px !important;
}

.header-container {
  padding: 0px 35px !important;
  height: 66px !important;
  z-index: 10 !important;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
  /* padding: 0px 35px !important; */
}

.Logoredirect > .MuiBox-root {
  margin-left: 0px !important;
}

.breadcrumb-container {
  /* padding-top:  30px !important;
  padding-bottom: 20px !important;
  margin-bottom: 0px !important; */
  background-color: #f7f7f7 !important;
  border-bottom: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;

}

.breadcrumb-link {
  color: #000 !important;
}

.breadcrumbs-class {
  margin-top: 10px !important;
}

.search-header {
  border: 1px solid #e1e1e1 !important;
  padding: 2px 4px !important;
  width: 100% !important;
}

.Mui-focusVisible {
  background-color: transparent !important;
}

.tootltipbody {
  background-color: #fff !important;
  opacity: 1 !important;
}

.tootltipbody {
  background: #333 !important;
  color: #fff !important;
  padding: 3px 10px 10px 10px !important;
}

.tooltip-text {
  color: #fff !important;
  font-weight: 600 !important;
}

.ag-picker-field-wrapper {
  top: -40px !important;
}

.export-btn {
  background: #0f6f39 !important;
  color: #fff !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}

.export-item {
  color: #000 !important;
  font-weight: 600 !important;
}

.export-item:hover {
  color: #000 !important;
  background: #f3f3f3 !important;
}

.export-item:hover > svg {
  color: #000 !important;
}

.grid-left {
  text-align: left !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.grid-right {
  text-align: right !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.add-docs {
  margin-bottom: 5px !important;
}

.back-cases {
  margin-bottom: 5px !important;
  padding: 7px 10px;
  border-radius: 4px !important;
}

.Cases-field {
  width: 100%!important;
  margin-top: 20px !important;
}

.MuiFormHelperText-contained {
  color: #e61a23 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.login-btn {
  margin-top: 30px !important;
}

.register-btn {
  margin-top: 45px !important;
}

.login-separator {
  margin: 20px 0px !important;
}

.error-message {
  margin: 0 auto !important;
  display: block !important;
  margin-top: 10px !important;
}

.weak-password {
  background: #dc3545 !important;
}

.medium-password {
  background: #ffc107 !important;
}

.strong-password {
  background: #28a745 !important;
}

.password-text {
  font-size: 11px !important;
  margin-top: 0px !important;
  display: block;
  text-align: center !important;
  color: #fff;
  padding-top: 5px !important;
  text-shadow: 1px 1px 3px #0e0e0e;
  letter-spacing: 0.5px;
}

.statusBar {
  width: 100% !important;
  height: 25px !important;
  border: 1px solid #cbcbcb !important;
  border-radius: 3px !important;
}

.password-cont {
  width: 100% !important;
  margin: 0 auto !important;
  margin-bottom: 15px !important;
}

.password-conts {
  width: 99% !important;
  margin: 0 auto !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
}

.awsauth-btn {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.verificationinput {
  margin: 0 auto !important;
  width: 100% !important;
}

.top-menu-item {
  padding: 0px !important;
  margin: 0px 15px !important;
}

.Mui-focusVisible {
  color: transparent !important;
}

.section-padding {
  padding: 25px 25px 40px 25px !important;
  box-shadow: none !important;
}

.Note-section {
  text-align: left !important;
  padding: 20px 20px !important;
  margin-bottom: 20px !important;
}
.note-icon {
  color: #492728 !important;
}

.header-btn {
  border: 1px solid #bcbcbc !important;
  margin-right: 15px !important;
}

.ag-watermark {
  display: none !important;
}

.ag-watermark-text {
  display: none !important;
  opacity: 0 !important;
}

.badge {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #64748b !important;
  overflow: hidden !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  padding: calc(0.17rem - 0px) 0.17rem !important;
  height: 0.4rem !important;
  border: 1px solid transparent !important;
  min-width: 0.4rem !important;
  font-weight: 600 !important;
  font-size: 0.625rem !important;
  letter-spacing: 0.04em !important;
  text-transform: uppercase !important;
  border-radius: 100% !important;
}


.bg-success {
  --tblr-bg-opacity: 1 !important;
  background-color: #925C54 !important;
}

.bg-uploaded {
  --tblr-bg-opacity: 1 !important;
  background-color: #64748b !important;
}

.bg-blue {
  --tblr-bg-opacity: 1 !important;
  background-color: #925C54 !important;
}

/* .active > .MuiButtonBase-root > .MuiListItemIcon-root > svg {
  color: #fff !important;
} */

.bg-orange {
  --tblr-bg-opacity: 1 !important;
  background-color: #925C54 !important;
}

.bg-danger {
  --tblr-bg-opacity: 1 !important;
  background-color: #925C54 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

/* .ag-theme-alpine [class^='ag-']:before {
  color: #b9b9b9 !important;
} */

.marked-text {
  height: max-content !important;
  color: #333 !important;
  padding: 0px 2px 0px 2px !important;
}

mark {
  background: yellow;
  color:#000;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
  padding: 12px;
 }

 .wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.MuiChip-outlined {
  float: left !important;
  width: 150px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.search_tooltiptexts__vNbjT {
  width: 200px !important;
}

.ag-theme-alpine .ag-pivot-mode-panel {
  display: none !important;
}

.MuiTooltip-tooltip {
  max-width: 500px !important;
  max-height: 150px !important;
  overflow-y: auto;
  margin: 0 auto !important;
  /* background-color: #492728 !important; */
}

.ag-pinned-right-header {
  margin-right: 100px;
}

.ag-horizontal-right-spacer.ag-scroller-corner {
  display: none !important;
}

.ag-header-row {
  position: absolute !important;
  overflow: initial !important;
}

.ag-header-cell {
  background: #222;
}

.ag-floating-filter {
  background: #f8f8f8 !important;
}

pre {
  counter-reset: line !important;
  line-height: 1.9 !important;
  width: 100% !important;
}

code {
  counter-increment: line;
  background: #ebebeb !important;
  color: #000 !important;
  margin-right: 7px !important;
  border-radius: 0px !important;
  padding: 5px !important;
}

code::before {
  content: counter(line);
  display: inline-block;
  width: 40px !important; 
  border-right: 1px solid #bdbdbd;
  padding: 0 .5em;
  margin-right: .5em;
  color: #888;
  text-align: right !important;
}


main {
  padding: 0px !important;
}

#header-bar {
  display: none !important;
}

select {
  outline: none !important;
}

#pdf-pagination-info {
  color: #fff !important;
}

.table-data > table {
  font-size: 13px !important;
}

.Mui-selected, .MuiTab-textColorPrimary:hover {
  background: #fff !important;
  border-radius: 0!important;
  box-shadow: none !important;
  color: #925c54 !important;
  border-bottom: 1px solid #925c54 !important;
}

.MuiTab-root {
  font-weight: 600 !important;
}

.formviewer {
  display: initial !important;
  margin-left: -7px !important;
}

.formviewtr {
  border: 1px solid #a7a7a7 !important;
  background: #dae4f0 !important;
  border-radius: 3px !important;
  padding: 6px !important;
}

.formviewtr > span {
  font-size: 12px;
  padding-right: 12px;
  padding-left: 12px;
  border-right: 1px solid #a0a6ad !important;
  width: 130px !important;
  display: inline-flex !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.formviewtr > span:hover{
  overflow: visible !important;
  min-width: 130px !important;
  width: auto !important;
}


.tableviewer {
  display: initial !important;
}

.tableviewtr {
  border: 1px solid #a7a7a7 !important;
  background: #fffce2 !important;
  border-radius: 3px !important;
  padding: 6px !important;
}



.tableviewtr > span {
  font-size: 12px;
  padding-right: 12px;
  padding-left: 12px;
  border-right: 0.5px solid #c5c5c5 !important;
  border-left: 1px solid #c5c5c5 !important;
  width: 130px !important;
  display: inline-flex !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}


.tableviewtr > span:hover{
  overflow: visible !important;
  min-width: 130px !important;
  width: auto !important;
}

.tableviewtr:first-child {
  background: #2a2a2a !important;
  color: #fff;
  font-weight: 700;
}

.MuiSelect-select {
  font-size: 1.1333333333333333rem !important;
  color: #000 !important;
}

.Main-topMargin {
  margin-top: 66px !important;
}



input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}


.group--actions--tr {
    opacity: 1 !important;
    /* display: none !important; */
}

.group--actions > button:nth-child(1) {
  display: none !important;
  opacity: 0 !important;
}

.group--actions > button:nth-child(2) {
  display: none !important;
  opacity: 0 !important;
}

.group--actions > button:nth-child(3) {
  display: none !important;
  opacity: 0 !important;
}

.qb-lite .widget--valuesrc, .qb-lite .rule--drag-handler, .qb-lite .rule--header {
  opacity: 1 !important;
}

.mui .widget--sep {
  margin: 5px 10px !important;
}

.rule--operator, .widget--widget, .widget--valuesrc, .widget--sep {
  margin: 0px 3px 0px 10px !important;
}


[role=button] {
  /* padding-bottom: 0px !important; */
  font-size: 15px !important;
  cursor: pointer !important;
}

.ag-paging-button {
  padding-bottom: 0px !important;
}

.breadcrumb-container  > h1 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}
/* .query-builder {
  margin: 0px !important;
}

.query-builder-container {
  padding: 0px !important;
} */

.group {
  padding-left: 0px !important;
  padding: 15px !important;
}

.group-or-rule-container {
  padding-bottom: 10px !important;
}

a:hover {
    color:#333;
    text-decoration: none !important;
}

/* .MuiOutlinedInput-notchedOutline > legend {
  display: none !important;
} */


/* .group {
  height: 265px !important;
} */

[role="tabpanel"] > .MuiBox-root {
  padding: 15px !important;
}

.rule--body--wrapper{
  text-align: left !important;
  float: left !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  border-right-width: 1px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: inherit !important;
}

.ag-row-selected {
  background-color: #fff !important;
}

.HighlightText {
  /* background: #492728; */
}

.active-class {
  border: 3px dotted #492728 !important;
  margin: 1px !important;
}

.MuiTabs-indicator {
  border: none !important;
}

.react-draggable {
  position: absolute !important;
  z-index: 10 !important;
  /* width: 100% !important; */
  right: 30px !important;
}

/* .MuiDropzonePreviewList-root {
  display: none !important;
} */
/* .MuiDropzonePreviewList-imageContainer {
  display: none !important;
} */

.Dropzone-Test > .MuiDropzoneArea-root > .MuiDropzonePreviewList-root {
  display: none !important;
}


.loader-parent {
  display:none !important;
}

.HighlightedContent{
  background:#FFE790;
}

.HighlightedNumber{
  background: #764bbd; 
  border-radius: 3px; 
  padding: 2px 5px; 
  color: #fff; 
  font-size: 12px;
}

.raw-data{
  /* content: "\a";
  white-space: pre; */
}

.css-pygswx-MuiLoadingButton-loadingIndicator {
    color: #fff !important;
}

.MuiLoadingButton-loadingIndicator {
  background: transparent !important;
  color: #fff !important;
  padding: 5px 5px !important;
  border-radius: 4px !important;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell, .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell, .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1px solid;
  border-color: #2196f3;
  border-color: transparent !important;;
}

.ag-theme-alpine .ag-ltr .ag-range-handle, .ag-theme-alpine .ag-ltr .ag-fill-handle {
  background: transparent !important;;
}

a, svg, select {
  cursor: pointer !important;
}

.ag-theme-alpine .ag-cell-range-selected-1:not(.ag-cell-focus), .ag-theme-alpine .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
  background: #fff !important;
}

input, textarea {
  color: #492728 !important;
}

.MuiSlider-thumb, .MuiSlider-track {
  color: #925C54 !important;
}

/* #overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
} */

option {
  background: #fff !important;
  color: #000 !important;
}

.Toastify__progress-bar--animated {
  -webkit-animation-direction: reverse !important;
  -moz-animation-direction: reverse !important;
  -ms-animation-direction: reverse !important;
  -o-animation-direction: reverse !important;
  animation-direction: reverse !important;
}

.Sidebarbg:nth-child(odd) {
  background: #faf6f5;
}

.Sidebarbg:nth-child(even) {
  background: #fff;
}

.ProcessBtn {
  pointer-events: initial;
  opacity: 1;
}

.DisableProcessBtn {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.ck-toolbar {
  display: none !important
}

.ck-editor__editable_inline {
  min-height: 400px;
}

p {
  color: #000 !important;
  line-height: 1.5 !important;
  font-size: 16px !important;
  margin: 0px 0px;
}

/* View Page */
.userHighlight {
  background: rgb(191, 238, 162);
  padding: 2px 3px;
  border-radius: 0px;
  margin: 0px 4px 4px 0px;
  color: rgb(34, 34, 34);
  cursor: pointer;
  display: inline-block;
}

.highlightBrandss {
  background: #d0e9f2;
  padding: 2px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
  cursor: pointer;
}

.highlightClaimss {
  background: rgb(242, 217, 208);
  padding: 2px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
  cursor: pointer;
}

.highlightAlls {
  background: #d0e9f2;
  padding: 2px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
  cursor: pointer;
}

.tagNumber {
  color: #fff;
  background: #111;
  padding: 3px 9px 4px 10px;
  border-radius: 3px;
  font-size: 0.7em;
  margin-top: -3px;
  float: left;
  margin-left: -8px;
  margin-right: 3px;
  cursor: pointer;
}


/* Copy Paste */

.highlightBrands {
  background: #FBCEB1;
  padding: 0px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
}

.unhighlightBrands {
  display: inline-block;
  background: transparent !important;
}


.highlightClaims {
  background: #F2D9D0;
  padding: 0px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
}

.unhighlightClaims {
  background: transparent !important;
}

.highlightEditorial {
  background: #F8C8DC;
  padding: 0px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
}


.unhighlightEditorial {
  background: transparent !important;
}


.highlightAll {
  background: #FBCEB1;
  padding: 0px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
}

.unhighlightAllClaims {
  background: rgb(242, 217, 208);
  padding: 0px 3px;
  border-radius: 0px;
  margin: 0px 0px 4px 0px;
  color: rgb(34, 34, 34);
  display: inline-block;
}

.Toastify__toast-body > div:last-child {
  width: 100px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ag-header-cell-text {
  letter-spacing: 1px;
}

.ag-cell-wrapper > span {
  /* white-space: pre-wrap;
  word-break: keep-all; */
  /* line-height: 1.6; */
  /* padding-top: 3px; */
}


.MuiTooltip-tooltip {
  background: rgb(73, 39, 40) !important;
  color: rgb(255, 255, 255) !important;
  font-size: 14px !important;
  box-shadow: 3px 3px 10px #444 !important;
}

.tag26Hide {
  display: none !important;
}

body::-webkit-scrollbar {
  width: 0em;
}

.raw-datas::-webkit-scrollbar {
  width: 0em;
}

.raw-pdf::-webkit-scrollbar {
  width: 0em;
}

/* .App::-webkit-scrollbar {
  width: 0em;
} */

/* .PDFHighlighterApp::-webkit-scrollbar {
  width: 0em;
}
*/
/* .PdfHighlighter::-webkit-scrollbar {
  width: 2em;
  height: 2em
}  */

/* .PdfHighlighter::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em
}
.PdfHighlighter::-webkit-scrollbar-button {
  background: #dbdbdb
}
.PdfHighlighter::-webkit-scrollbar-track-piece {
  background: #bfbfbf
}
.PdfHighlighter::-webkit-scrollbar-thumb {
  background: #eee
}​ */


textarea::-webkit-scrollbar {
  width: 0em;
}

/* ::-webkit-scrollbar {
  width: 0em;
} */

/* #page-container::-webkit-scrollbar {
  width: 0em;
} */

.page-container {
  scrollbar-width: thin !important;
}

.ck.ck-editor {
  max-height: 75vh !important;
  overflow-y: scroll;
}

.rcw-header > span {
  display: none !important;
}

.rcw-title {
  color: #fff !important;
}

.rcw-conversation-container .rcw-title {
  padding: 0px 0 !important;
}

.rcw-picker-btn {
  display: none !important;
}

.rcw-launcher {
  background: #492728 !important;
}

.rcw-conversation-container .rcw-header {
  background-color: #5d3537 !important; 
}

.rcw-client .rcw-message-text {
  background-color: #d2c2c3 !important;
}

.rcw-conversation-container {
  /* width: 50% !important; */
  width: 600px;
}

/* .rcw-conversation-container {
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  min-width: 500px;
  max-width: 100vw;
  position: relative;
} */

.rcw-response .rcw-message-text {
  max-width: 100% !important;
}

.rcw-widget-container {
  z-index: 3 !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  font-family: "agGridAlpine" !important;
}

.ag-column-drop-vertical {
  display: none !important;
}

/* .Tip__card > div > div {
  display: none !important;
} */

.Tip__compact {
  background: #492728 !important;
  box-shadow: -1px 0px 8px #9b9b9b !important;
}

.Tip__card textarea {
  display: none !important;
}

.Tip__card input[type="submit"] {
  background: #492728 !important;
  border-radius: 3px !important;
  color: #fff !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 3px 6px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif !important;
  cursor: pointer;
}

/* .Tip__card {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
} */

.Highlight__label {
  color: #fff !important;
  background: #111;
  padding: 3px 3px 3px 3px;
  border-radius: 3px;
  font-size: 9px;
  margin-top: -3px;
  float: right;
  margin-left: -7px;
  margin-right: 0px;
  z-index: 10;
}

.Highlight__delete {
  color: #fff !important;
  background: #F44336;
  padding: 3px 3px 3px 3px;
  border-radius: 100%;
  font-size: 7px;
  margin-top: -5px;
  z-index: 10;
  right: -5px;
  opacity: 1;
  cursor: pointer !important;
}

.Claimlabel {
  font-weight: 700 !important;
  margin-bottom: 2px;
  font-size: 14px;
}

.PdfHighlighter {
  padding: 5px 0px 20px 0px;
}

.Highlight__part {
  z-index: 1; 
}

/* .PDFHighlighterApp {
  min-height: 100vh;
} */

/* @media only screen and (max-width: 1200px) {
  .PDFHighlighterApp {
    min-height: 65vh;
  }
}

@media only screen and (max-width: 1400px) and (min-width: 1201px) {
  .PDFHighlighterApp {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1401px) {
  .PDFHighlighterApp {
    min-height: 75vh;
  }
}

@media only screen and (min-width: 1601px) {
  .PDFHighlighterApp {
    min-height: 90vh;
  }
} */

.textLayer span, .textLayer br {
  /* font-family: 'Roboto Regular' !important; */
  /* letter-spacing: 0.14em !important; */
  /* color: red !important; */
  /* letter-spacing: 1px; */
  /* font-weight: 600 !important; */
}


/* .tag23 {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
} */

/* .hide-product-type {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
} */


.rsw-toolbar {
  display: none !important;
}

.rsw-editor {
  /* height: 400px !important; */
}

.Toastify__toast-container--bottom-right {
  bottom: 6em !important;
}

.rsw-ce {
  height: 60vh !important;
  overflow: auto !important;
  width: 100% !important;
  max-width: 1000px !important;
  min-width: 100% !important;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}