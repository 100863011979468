$inabia-orange: #492728;
$header-height: 88px;
.tabs {
  &:global(.MuiTabs-root) {
    background: none !important;
    height: $header-height !important;
    :global(.MuiTabs-indicator) {
      display: none;
    }
  }
}

.tab {
  &:global(.MuiTab-textColorPrimary) {
    background-color: white !important;
    color: black !important;
    min-width: 0px !important;
    height: $header-height !important;
    box-shadow: none !important;
    &:hover {
      // @extend .tab:global(.MuiTab-textColorPrimary);
      color: $inabia-orange !important;
      border-bottom: 2px solid $inabia-orange !important;
      font-weight: bold !important;
    }

    &:global(.Mui-selected) {
      // @extend .tab:global(.MuiTab-textColorPrimary);
      color: $inabia-orange !important;
      border-bottom: 2px solid $inabia-orange;

      &:hover {
        // @extend .tab:global(.Mui-selected);
        color: black !important;
        font-weight: bold !important;
      }
    }
  }
}


.headeremail {
  color: #000 !important;
  font-weight: 600 !important;
  text-transform: lowercase !important;
}

.headeremails {
  color: #000 !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
}

.username {
  color: #000 !important;
  font-weight: 600 !important;
}

.SubmenuIcons {
  min-width: 30px;
}

.ExpandHover:hover {
  color: #492728  !important;
}

.subOptions {
      padding: 2px 0px 8px 0px;
}